<!--
 * @Author: lbh
 * @Date: 2021-04-28 15:17:32
 * @LastEditors: lbh
 * @LastEditTime: 2024-02-29 14:59:36
 * @Description: 圖文模板
-->
<template>
  <div>
    <!-- v-if="bgImg" -->
    <div
      :style="`background-image:url('${bgImg}');`"
      class="graphic-bg"
    >
      <el-row
        class="graphic-box"
        :class="type != '1000' ? type == '600' ? 'graphic-box_1 graphic-box_2' : 'graphic-box_1' : ''"
        :style="`${but.bgImg ? `margin-bottom:0 !important;` : ``}`"
      >
        <el-col :span="12">
          <el-carousel
            :style="`height:${height + 30}px;`"
            indicator-position="outside"
          >
            <el-carousel-item
              v-for="(item,index) in imgs"
              :key="index"
            >
              <el-image
                class="img"
                :src="item"
                fit="cover"
              />
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :span="12">
          <!-- 主標題 -->
          <div
            class="big-title theme_color"
            v-text="title"
            v-if="title"
          ></div>
          <img
            class="big-logo"
            v-if="logo"
            :src="logo"
          />
          <!-- 內容 -->
          <div
            class="items"
            v-for="(item,index) in datas"
            :key="index"
          >
            <div
              class="title"
              v-text="item.title"
            ></div>
            <div
              class="item"
              v-for="(ite,ind) in item.items"
              :key="ind"
              :style="ite.style"
            >
              <span v-text="ite.title"></span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 底部圖片 -->
    <div
      v-if="bImg"
      class="bimg"
    >
      <img
        :src="bImg"
        :style="`margin-${bImgP}:auto;width:${bImgP != `center` && type == '1000' ? `50%`:`100%`};`"
      />
    </div>
    <!-- 底部圖片 2 -->
    <div
      class="but-box"
      :class="type == '600' ? 'but-box_1' :  ''"
      v-if="but.bgImg"
      :style="`background-image:url('${but.bgImg}')`"
    >
      <!-- <el-image
        class="bg-img"
        :src="but.bgImg"
        fit="cover"
      ></el-image> -->
      <img
        v-if="but.otherImage"
        :src="but.otherImage"
        class="otherImage"
      />
      <div class="item">

        <div
          class="title"
          v-text="but.title"
        ></div>
        <img
          class="img"
          :src="but.img"
        />
        <div
          class="title"
          v-text="but.content"
        ></div>
        <sButton
          v-if="button.text"
          :index="button.index"
          :text="button.text"
          :go="button.go"
          :type="1"
          style_="color:#FFF;border-color:rgba(255, 255, 255, 0.75);margin-top:30px;"
        />
      </div>
    </div>
  </div>
</template>
<script>
import sButton from "@/components/commo/button";
export default {
  name: "self-graphic",
  components: {
    sButton
  },
  props: {
    // 輪播組
    imgs: {
      default: []
    },
    // 數據組
    datas: {
      default: []
    },
    // 底部圖片
    bImg: {
      default: ''
    },
    // 底部圖片位置 center left  right
    bImgP: {
      default: "center"
    },
    // 標題
    title: {
      default: ""
    },
    // logo
    logo: {
      default: ''
    },
    // 背景圖片
    bgImg: {
      default: ''
    },
    // 圖片高度
    height: {
      default: 0
    },
    // 頁面寬度
    type: {
      default: '1000'
    },
    but: {
      default: () => {
        return {
          bgImg: '',
          img: '',
          title: '',
          content: '',
          otherImage: ''
        }
      }
    },
    button: {
      default: () => {
        return {
          text: "",
          index: 0,
          go: ""
        }
      }
    }
  },
  mounted () { },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.graphic-box_1 {
  display: flex;
  flex-direction: column;
  & /deep/ .el-col {
    width: 100% !important;
  }
}
.graphic-box_2 {
  margin: 30px 0 !important;
}
.graphic-box {
  & /deep/ .el-col {
    padding: 20px;
    .el-carousel__container {
      height: calc(100% - 30px);
      .img {
        height: 100%;
        width: 100%;
      }
    }
  }
  margin: 90px 10%;
  .big-title {
    font-size: 36px;
  }
  .items {
    line-height: 30px;
    .title {
      font-size: 25.5px;
      margin-top: 60px;
    }
    .item {
      font-size: 16px;
    }
  }
}

.bimg {
  margin: 90px 10% 50px;
  display: flex;
  img {
    max-width: 100%;
  }
}

.graphic-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: Left Top;
}

.but-box {
  // position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .bg-img {
    height: 700px;
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
  }
  .otherImage {
    width: 100%;
    margin-top: 100px;
  }
  .item {
    // position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    backdrop-filter: blur(4px);
    padding: 120px 0;
    .img {
      max-width: 80%;
    }
  }
}

.but-box_1 {
  .item {
    font-size: 21px !important;
  }
}

.big-logo {
  height: 118px;
}
</style>
